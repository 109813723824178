import {Switch} from '@headlessui/react'
import React from 'react'
import Text from '@component/Text/Text'
import IconCheckBoxNone from '@svg/common/ic_checkbox_none.svg'
import IconCheckBoxNoneDark from '@svg/common/ic_checkbox_none_dark.svg'
import IconCheckBoxCheck from '@svg/common/ic_checkbox_check.svg'
import IconCheckBoxCheckDark from '@svg/common/ic_checkbox_check_dark.svg'
import IconCheckBoxDisabled from '@svg/common/ic_checkbox_disabled.svg'
import IconCheckBoxDisabledDark from '@svg/common/ic_checkbox_disabled_dark.svg'
import useDark from '@hook/useDark'

interface IProps {
    checked?: boolean
    onChanged?: (checked: boolean) => void
    className?: string
    disabled?: boolean
    text?: string
    textClassName?: string
    children?: React.ReactNode
    dataCy?: string
    containerClassName?: string
}

const WizCheckBox: React.FC<IProps> = ({
    checked = false,
    onChanged,
    className,
    disabled = false,
    text,
    textClassName,
    children,
    dataCy,
    containerClassName,
}) => {
    const {isRenderDark} = useDark()

    return (
        <div data-cy={dataCy} className={`${containerClassName}`}>
            <Switch checked={checked} onChange={onChanged}>
                {({checked}) => (
                    <div className={`flex items-center ${className} `}>
                        {disabled ? (
                            !isRenderDark ? (
                                <IconCheckBoxDisabled className={'flex cursor-pointer flex-none'} />
                            ) : (
                                <IconCheckBoxDisabledDark className={'flex cursor-pointer flex-none'} />
                            )
                        ) : !isRenderDark ? (
                            checked ? (
                                <IconCheckBoxCheck className={'flex cursor-pointer flex-none'} />
                            ) : (
                                <IconCheckBoxNone className={'flex cursor-pointer flex-none'} />
                            )
                        ) : checked ? (
                            <IconCheckBoxCheckDark className={'flex cursor-pointer flex-none'} />
                        ) : (
                            <IconCheckBoxNoneDark className={'flex cursor-pointer flex-none'} />
                        )}
                        <Text className={`ml-[10px] ${textClassName}`}>{text}</Text>
                    </div>
                )}
            </Switch>
            {children && <div className={'ml-[30px]'}>{children}</div>}
        </div>
    )
}

export default WizCheckBox
