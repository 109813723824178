import React, {useEffect, useState} from 'react'
import Text from '@component/Text/Text'
import PlusSVG from '@svg/common/ic_input_plus.svg'
import MinusSVG from '@svg/common/ic_input_minus.svg'
import BigNumber from 'bignumber.js'

interface IProps {
    className?: string
    onChange?: (num: number) => void
    stepSize?: number
    num?: number
    textClassName?: string
    max?: number
    min?: number
    suffix?: string
    disabled?: boolean
}

const WizNumberControl = (
    {className, disabled, num, onChange, stepSize = 1, max, min, textClassName, suffix}: IProps,
    ref: any,
) => {
    const [numberText, setNumberText] = useState(String(num) ?? '0')

    const onIncrease = () => {
        const inputNumber = new BigNumber(numberText)
        setNumberText(checkValue(inputNumber.plus(stepSize).toNumber()).toString())
    }

    const onDecrease = () => {
        const inputNumber = new BigNumber(numberText)
        setNumberText(checkValue(inputNumber.minus(stepSize).toNumber()).toString())
    }

    const checkValue = (value: number) => {
        if (max !== undefined && value >= max) {
            return max
        } else if (min !== undefined && value <= min) {
            return min
        } else {
            return value
        }
    }

    useEffect(() => {
        if (Number(numberText) !== num) {
            onChange(Number(numberText))
        }
    }, [num, numberText, onChange])

    useEffect(() => {
        setNumberText(num?.toString())
    }, [num])

    return (
        <div
            className={`flex items-center min-w-[318px] border rounded-[5px] overflow-hidden ${
                disabled
                    ? 'bg-gray09 dark:bg-bg_dark_white02 border-gray06 dark:border-dark_gray06'
                    : 'bg-white dark:bg-bg_dark_white02 border-gray01 dark:border-dark_gray01'
            } ${className} sm:min-w-[auto]`}>
            <button
                className={
                    'flex-none w-[64px] flex justify-center items-center hover:bg-gray07 dark:hover:bg-dark_gray07 py-[13px]'
                }
                disabled={disabled}
                onClick={() => {
                    onDecrease()
                }}>
                <MinusSVG
                    className={`w-[24px] ${
                        disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray01 dark:fill-dark_gray01'
                    }`}
                />
            </button>
            <div className={'flex-1 border-x border-gray07 dark:border-dark_gray07 flex items-center'}>
                <input
                    ref={ref}
                    className={`flex-1 text-center text-h3 bg-transparent ${textClassName} ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : ''
                    } sm:w-full`}
                    type={'number'}
                    value={numberText ?? ''}
                    disabled={disabled}
                    onChange={e => {
                        setNumberText(checkValue(Number(e.target.value)).toString())
                    }}
                />
                {suffix && <Text className={'text-body2 text-gray04 dark:text-dark_gray04 pr-[10px]'}>{suffix}</Text>}
            </div>
            <button
                className={
                    'flex-none w-[64px] flex justify-center items-center hover:bg-gray07 dark:hover:bg-dark_gray07 py-[13px]'
                }
                disabled={disabled}
                onClick={() => {
                    onIncrease()
                }}>
                <PlusSVG
                    className={`w-[24px] ${
                        disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray01 dark:fill-dark_gray01'
                    }`}
                />
            </button>
        </div>
    )
}

export default React.forwardRef(WizNumberControl)
